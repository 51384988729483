import Vue from 'vue'
import './plugins/meta'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import vueHeadful from 'vue-headful'
import FlagIcon from 'vue-flag-icon'
import Truncate from 'vue-truncate'
import { firebaseConfig } from './helpers/firebaseConfig'
import { firebase } from '@firebase/app'
import VuetifyConfirm from 'vuetify-confirm'

require('firebase/auth')
require('firebase/firestore')
require('firebase/analytics')
require('firebase/app')
firebase.initializeApp(firebaseConfig)
firebase.analytics()

Vue.prototype.$firebaseAnalytics = firebase.default.analytics()
Vue.prototype.$firebaseAuth = firebase.default.auth()
Vue.prototype.$firebaseDatabase = firebase.default.firestore()

firebase.auth().onAuthStateChanged(user => {
  if (user != null && user.email.split('@')[1] !== 'oriaagriculture.com' && user.email.split('@')[1] !== 'matwright.dev') {
    firebase.auth().signOut()
    alert('The email entered is not authorized')
  }
})

Vue.component('VueHeadful', vueHeadful)
Vue.use(FlagIcon)
Vue.use(VuetifyConfirm, { vuetify })
Vue.config.productionTip = false
Vue.use(Truncate)

  new Vue({
  mounted () {
  },
    router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
