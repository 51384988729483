import Vue from 'vue'
import Router from 'vue-router'
import RouterView from './views/RouterView.vue'
import i18n from './i18n'
Vue.use(Router)
var lang
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang?',

      component: RouterView,

      beforeEnter (to, from, next) {
          lang = to.params.lang
        if (!['en', 'de', 'fr'].includes(lang)) {
           lang = i18n.locale
        }

        if (i18n.locale !== lang) {
          i18n.locale = lang
        }

        return next()
      },
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/views/Home'),
        },
        {
          path: 'home',
          name: 'home',
          component: () => import('@/views/Home'),
        },
        {
          path: 'process',
          name: 'process',
          component: () => import('@/views/Process'),
        },
        {
          path: 'user/login',
          name: 'login',
          meta: { hero: 'home' },

          component: () => import('@/views/Login'),
        },
        {
          path: 'user/account',
          name: 'account',
          meta: {
            hero: 'home',
            auth: true,
          },

          component: () => import('@/views/Account'),
        },

    {
      path: 'about-us',
      name: 'aboutus',
      component: () => import('@/views/AboutUs'),
    },
    {
      path: 'about-us/news',
      name: 'news',
      component: () => import('@/views/News'),
    },
        {
          path: 'category/:category',
          name: 'category',

          component: () => import('@/views/Products'),
        },
    {
      path: 'products',
      name: 'products',

      component: () => import('@/views/Products'),
    },

    {
      path: 'products/:company/',
      name: 'company',

      component: () => import('@/views/Company'),
    },
    {
      path: 'products/:company/:product',
      name: 'product',
props: true,
      component: () => import('@/views/Product'),
    },

    {
      path: 'about-us/companies-we-represent',
      name: 'companieswerepresent',
      component: () => import('@/views/Companies'),
    },

        {
          path: 'legal',
          name: 'legal',
          component: () => import('@/views/Legal'),
        },

        {
          path: 'sales-terms',
          name: 'sales-terms',
          component: () => import('@/views/SalesTerms'),
        },
        {
          path: 'privacy',
          name: 'privacy',
          component: () => import('@/views/Privacy'),
        },

    {
      path: 'contact',
      name: 'contact',
      component: () => import('@/views/Contact'),
    },
      ],
},
  ],

})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    Vue.prototype.$firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        next()
      } else {
        console.log('redirect to login')
        next({
          path: '/user/login',
        })
      }
    })
  } else {
    next()
  }
})
// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require('vue-analytics').default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development',
    },
  })
}

export default router
