import { set, toggle } from '@/utils/vuex'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: null,
    user: {
      loggedIn: false,
      data: null,
    },
  },
  getters: {
    user (state) {
      return state.user
    },
  },
  mutations: {
    SET_LOGGED_IN (state, value) {
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      state.user.data = data
    },
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer'),
  },
  actions: {
    fetchUser ({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
        })
      } else {
        commit('SET_USER', null)
      }
    },
  },
})
