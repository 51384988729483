<template>
  <v-app light>
    <v-img
      style="cursor: pointer; position: absolute;z-index: 10000;"
      alt="Ori Agriculture"
      onclick="window.location='/'"
      class="hidden-md-and-up"
      max-width="100px"
      src="/static/oria-agriculture@white_rect.png"
    />
    <core-drawer />

    <core-app-bar style="background-color:rgba(0,117,131,1) !important" />

    <core-view />

    <core-footer />
  </v-app>
</template>
<style lang="sass">
  $body-font-family: 'Arial'
  $heading-font-family: 'Arial'
  .v-application
    background-color: #ffffff !important
  .v-application .body-1
    font-family: $body-font-family  !important
  .v-application .headline
    font-family: $body-font-family  !important
  .v-application .title
    font-family: $body-font-family  !important
</style>
<script>

  export default {
    components: {

      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreFooter: () => import('@/components/core/Footer'),
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreView: () => import('@/components/core/View'),
    },

    data: () => ({}),
    metaInfo: {
      titleTemplate: '%s — Oria Agriculture',
    },
    methods: {

    },
  }

</script>
