export const firebaseConfigMat = {
  apiKey: 'AIzaSyDu2ezLnFQKqmxorCScLT7U9h96-27RWeM',
  authDomain: 'oria-staging.firebaseapp.com',
  databaseURL: 'https://oria-staging.firebaseio.com',
  projectId: 'oria-staging',
  storageBucket: 'oria-staging.appspot.com',
  messagingSenderId: '400323810077',
  appId: '1:400323810077:web:34e5738465d262f94ec7f4',
  measurementId: 'G-1ZE73VKZW0',
}

// dev
export const firebaseConfigDISABLED = {
  apiKey: 'AIzaSyBJueyScWFY4pUOIB63thrVaaI9u9cwjkw',
  authDomain: 'oria-agriculture-staging.firebaseapp.com',
  databaseURL: 'https://oria-agriculture-staging.firebaseio.com',
  projectId: 'oria-agriculture-staging',
  storageBucket: 'oria-agriculture-staging.appspot.com',
  messagingSenderId: '399783319755',
  appId: '1:399783319755:web:cc81a07dd54a87da78234e',
  measurementId: 'G-QS231T6980',
}

// Your web app's Firebase configuration
// prod
export const firebaseConfig = {
  apiKey: 'AIzaSyDTh4nz-VPBvUBf891Basrob2Mw5SL0LMM',
  authDomain: 'oria-agriculture-website.firebaseapp.com',
  databaseURL: 'https://oria-agriculture-website.firebaseio.com',
  projectId: 'oria-agriculture-website',
  storageBucket: 'oria-agriculture-website.appspot.com',
  messagingSenderId: '155150692512',
  appId: '1:155150692512:web:2fa869b97d786cc7cf59c5',
  measurementId: 'G-LJEYTNMGNJ',
}
